import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import '../i18n/init';
import { jssPreset, StylesProvider } from '@mui/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import i18next from 'i18next';
import { DefaultSeo } from 'next-seo';

import { create } from 'jss';
import rtl from 'jss-rtl';
import '../styles.css';

import { createTheme, THEMES } from '../theme';
import CookiesConsent from '../components/CookiesConsent';
import GoogleAnalytics from '../components/GoogleAnalytics';
import FacebookPixel from '../components/FacebookPixel';
import { useRouter } from 'next/router';
import Hotjar from '../components/Hotjar';
import Tawk from '../components/Tawk';
import GoogleOptimize from '../components/GoogleOptimize';
import LinkedInInsightTag from '../components/LinkedInInsightTag';
import NagishLi from '../components/NagishLi';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const lang = pageProps.language || router.query.lang || 'en';

  i18next.changeLanguage(lang);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  React.useEffect(langChanged, [i18next.language]);

  function langChanged() {
    const direction = i18next.language === 'he' ? 'rtl' : 'ltr';
    document.body.dir = direction;
  }

  const title = i18next.t('meta.title', 'Easy clinical records software | Doxer.io');
  const description = i18next.t(
    'meta.description',
    'Doxer.io provides a new way to manage your medical records with the ultimate cloud-based software.',
  );

  return (
    <React.Fragment>
      <Head>
        <title>{title}</title>
        <link rel="icon" sizes="192x192" href="/favicon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/apple/apple-icon.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple/apple-icon-180x180.png" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <GoogleOptimize />
      </Head>
      <FacebookPixel />
      <GoogleAnalytics />
      <LinkedInInsightTag />
      <Tawk />
      <Hotjar />
      <CookiesConsent />
      <NagishLi />
      <DefaultSeo
        title={title}
        description={description}
        openGraph={{
          type: 'website',
          url: `https://www.doxer.io/${lang}/`,
          title,
          description,
          images: [
            {
              url: 'https://www.doxer.io/images/og_cover.png',
              width: 2500,
              height: 1330,
              alt: i18next.t('meta.logo_alt', 'Doxer Medical Technologies LTD.'),
            },
          ],

          site_name: i18next.t('meta.site_name', 'Doxer.io'),
        }}
        twitter={{
          cardType: 'summary_large_image',
        }}
        facebook={{
          appId: '173102281603105',
        }}
      />
      <StyledEngineProvider injectFirst>
        <ThemeProvider
          theme={createTheme({
            theme: THEMES.LIGHT,
            direction: lang === 'he' ? 'rtl' : 'ltr',
          })}>
          <StylesProvider jss={jss}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            {/*<CssBaseline />*/}
            <Component {...pageProps} />
          </StylesProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  );
};

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default MyApp;
