import React from 'react';
import i18next from 'i18next';

const NagishLi = ({}) => {
  const lang = i18next.language.substring(0, 2);
  console.log('lang', lang);
  return (
    <React.Fragment>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          nagishli_config = {
            language: "${lang}",
            color: "blue"
            };
      `,
        }}
      />

      <script src="/nagishli_beta.js?v=3.0b" charSet="utf-8" defer />
    </React.Fragment>
  );
};

NagishLi.propTypes = {};

NagishLi.defaultProps = {};

export default NagishLi;
