import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { languages, defaultLanguage } from './config';
import config from '../config';

const locales = Object.assign(
  {},
  ...Object.keys(languages).map(index => {
    return {
      [languages[index]]: {
        common: require(`locales/${languages[index]}/common.json`),
      },
    };
  }),
);

const detection = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupCookie: 'lng',
  lookupLocalStorage: 'lng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' },
};

i18next.use(LanguageDetector).init({
  // detection: detection,
  fallbackLng: defaultLanguage,
  resources: locales,
  ns: ['common'],
  defaultNS: 'common',
  returnObjects: true,
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },

  nsSeparator: false,
  keySeparator: false,
  saveMissing: config.env === 'development',
  updateMissing: false,
  appendNamespaceToMissingKey: true,
  missingKeyHandler: (lng, ns, key, fallbackValue, updateMissing, options) => {
    fetch(`http://localhost:${4001}/locales/add/${lng}/${ns}`, {
      headers: {
        'Content-Type': ' application/json',
      },
      body: JSON.stringify({
        key,
        value: fallbackValue || key,
        comment: options && options.comment,
      }),
      method: 'POST',
    }).catch(error => {});
  },
});

export default i18next;
