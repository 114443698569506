export default {
  caption: {
    fontSize: 16,
    lineHeight: 1.75,
    color: '#6a778c',
  },

  body1: {
    // fontWeight: 400,
    // lineHeight: 1.4
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5
  },
  h1: {
    fontWeight: 500,
    fontSize: 48,
    lineHeight: 58,
  },
  h2: {
    // fontWeight: 500,
    // fontSize: 29,
    // letterSpacing: '-0.24px'
  },
  h3: {
    fontSize: 34,
    lineHeight: '44px',
    // color: '#5294ff',
  },
  h4: {
    fontSize: 24,

    // fontWeight: 400,
    // fontSize: 20,
    // letterSpacing: '-0.06px',
    // lineHeight: 1
  },
  h5: {
    // fontWeight: 400,
    // fontSize: 16,
    // letterSpacing: '-0.05px',
    // lineHeight: 1
  },
  h6: {
    // fontWeight: 400,
    // fontSize: 14,
    // letterSpacing: '-0.05px',
    // lineHeight: 1
  },
  overline: {
    fontWeight: 500,
  },
};
