/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import merge from 'lodash/merge';
import { responsiveFontSizes, createTheme as createMuiTheme } from '@mui/material/styles';
import * as colors from '@mui/material/colors';
import typography from './typography';
import { softShadows, strongShadows } from './shadows';
// import { THEMES } from '../constants';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

const spacing = [0, 8, 16, 20, 32, 64];

const baseConfig = {
  direction: 'ltr',
  spacing: d => (d >= 6 ? d * 8 : spacing[d]),
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  typography,
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },
  },
};

const themeConfigs = [
  {
    name: THEMES.LIGHT,
    components: {
      MuiInputBase: {
        defaultProps:{
          size: 'small',
        },
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 1,
              color: colors.blueGrey[600],
            },
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            color: 'white',
            textTransform: 'none',
            height: 40,
            fontSize: 18,
            fontWeight: 400,
            letterSpacing: 0,
            padding: '10px 20px',

            '&:hover': {
              backgroundColor: '#5294ff',
              boxShadow: '0 4px 10px 0 rgba(82, 148, 255, 0.6)',
            },

            '&:disabled': {
              color: 'white',
              opacity: 0.35,
              backgroundColor: '#aeb8d3',
            },

            '&:active': {
              backgroundColor: '#3967b2',
            },
          },
          containedSecondary: {
            color: '#5294ff',
            backgroundColor: 'white',
            textTransform: 'none',
            height: 40,
            fontSize: 18,
            fontWeight: 400,
            letterSpacing: 0,
            padding: '10px 20px',

            '&:hover': {
              backgroundColor: '#d3e3ff',
              boxShadow: '0 4px 10px 0 rgba(82, 148, 255, 0.6)',
            },

            '&:disabled': {
              color: 'white',
              opacity: 0.35,
              backgroundColor: '#ffffff',
            },

            '&:active': {
              backgroundColor: '#ffffff',
            },
          },

          outlinedPrimary: {
            textTransform: 'none',
            height: 40,
            fontSize: 18,
            fontWeight: 400,
            letterSpacing: 0,
            padding: '10px 20px',
            // padding: '26px 50px',

            border: 'solid 1px #5294ff',

            '&:hover': {
              boxShadow: '0 4px 10px 0 rgba(82, 148, 255, 0.6)',
            },

            '&:disabled': {
              color: 'white',
              opacity: 0.35,
              backgroundColor: '#aeb8d3',
            },

            '&:active': {
              color: 'white',
              backgroundColor: '#3967b2',
            },
          },

          outlinedSecondary: {
            textTransform: 'none',
            height: 40,
            fontSize: 18,
            fontWeight: 400,
            letterSpacing: 0,
            // padding: '10px 20px',
            padding: '10px 35px',
            border: 'solid 1px #5294ff',
            color: '#5294ff',

            '&:hover': {
              border: 'solid 1px #5294ff',
              boxShadow: '0 4px 10px 0 rgba(82, 148, 255, 0.6)',
              // backgroundColor: 'rgba(255, 255, 255, 0.3)',
              // boxShadow: '0 4px 10px 0 rgba(82, 148, 255, 0.6)'
            },

            '&:disabled': {
              color: 'white',
              opacity: 0.35,
              backgroundColor: '#aeb8d3',
            },

            '&:active': {
              color: 'white',
              backgroundColor: '#3967b2',
              // backgroundColor: '#3967b2'
            },
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          primary: {
            color: 'white',
          },
        },
      },
    },
    palette: {
      mode: 'light',
      action: {
        active: '#ebedf4',
      },
      background: {
        default: colors.common.white,
        dark: '#ebedf4',
        paper: colors.common.white,
      },
      primary: {
        main: '#5294ff',
        secondary: '#edf4ff',
      },
      secondary: {
        // main: '#5850EC'
        // main: '#11cb5f'
        main: colors.common.white,
      },
      text: {
        primary: '#0f326c',
        secondary: '#6a778c',
      },
    },
    shadows: softShadows,
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },

      MuiCard: {
        elevation: 0,
      },

      MuiTextField: {
        variant: 'outlined',
        size: 'small',
        // margin: 'dense'
      },
    },
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34',
      },
      primary: {
        main: '#8a85ff',
      },
      secondary: {
        main: '#8a85ff',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
      },
    },
    shadows: strongShadows,
  },
  {
    name: THEMES.UNICORN,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d',
      },
      primary: {
        main: '#a67dff',
      },
      secondary: {
        main: '#a67dff',
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4',
      },
    },
    shadows: strongShadows,
  },
];

export function createTheme(settings = {}) {
  let themeConfig = themeConfigs.find(theme => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createMuiTheme(merge({}, baseConfig, themeConfig, { direction: settings.direction || 'ltr' }));

  if (false && settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
