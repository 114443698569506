import React from 'react';
import config from 'config';
import Image from 'next/image';
import i18next from 'i18next';

const FacebookPixel = () => {
  if (!config.fbPixel) {
    return null;
  }

  return (
    <React.Fragment>
      <script
        dangerouslySetInnerHTML={{
          __html: `
                      !function(f,b,e,v,n,t,s)
                      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                      n.queue=[];t=b.createElement(e);t.async=!0;
                      t.src=v;s=b.getElementsByTagName(e)[0];
                      s.parentNode.insertBefore(t,s)}(window, document,'script',
                      'https://connect.facebook.net/en_US/fbevents.js');
                      fbq('init', '${config.fbPixel}');
                      fbq('track', 'PageView');
                      `,
        }}
      />
      <noscript>
        <span style={{ display: 'none' }}>
          <Image
            unoptimized={true}
            src={`https://www.facebook.com/tr?id=${config.fbPixel}&ev=PageView&noscript=1`}
            alt={i18next.t('index.facebook_pixel', 'Facebook Pixel')}
            width={1}
            height={1}
          />
        </span>
      </noscript>
    </React.Fragment>
  );
};

export default FacebookPixel;
