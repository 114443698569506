function getConfig() {
  const config = {
    env: process.env.NODE_ENV,
    emailjs: {
      serviceID: 'service_v0zz3fq',
      userID: 'user_E4jpVBd7mV2FSVuIQ4dh3',
      contactFormTemplateID: 'template_s4hd84k',
      emailHomeFormTemplateID: 'template_d9ibetq',
    },
    siteUrl: 'https://doxer.io',
    appUrl: 'https://app.doxer.io',
    tawk: false,
  };

  switch (config.env) {
    case 'development':
      // config.gaCode = 'UA-176055527-2';
      break;
    case 'production':
      config.fbPixel = '616003402676682';
      config.gaCode = 'UA-176055527-1';
      config.linkedInPartnerId = '3820170';
      config.gaOptimize = 'OPT-KRN7VLX';
      config.hotjar = true;
      config.tawk = {
        he: '61a150ad53b398095a664a03/1flf5uda3',
        en: '61a150ad53b398095a664a03/1flf269s8',
      };
      break;
  }

  return config;
}

export default Object.freeze(getConfig());
