import React from 'react';
import config from 'config';

const GoogleOptimize = () => {
  if (!config.gaOptimize) {
    return null;
  }

  return <script src={`https://www.googleoptimize.com/optimize.js?id=${config.gaOptimize}`} />;
};

export default GoogleOptimize;
