import React from 'react';
import config from 'config';

const GoogleAnalytics = () => {
  if (!config.gaCode) {
    return null;
  }

  return (
    <React.Fragment>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.gaCode}`} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${config.gaCode}');
        `,
        }}
      />
    </React.Fragment>
  );
};

export default GoogleAnalytics;
