import React from 'react';
import config from 'config';
import i18next from 'i18next';

const Tawk = () => {
  if (!config.tawk) {
    return null;
  }

  const lang = i18next.language.substring(0, 2);

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `
var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/${lang === 'he' ? config.tawk.he : config.tawk.en}';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();

      `,
      }}
    />
  );
};

export default Tawk;
