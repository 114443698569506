import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CookieBanner } from '@palmabit/react-cookie-law';
import i18next from 'i18next';

const useStyles = makeStyles(theme => ({
  root: {
    direction: ({ len }) => (len == 'he' ? 'rtl!important' : 'ltr'),
    top: 'unset!important',
    bottom: 0,
    borderTop: 'solid 1px #0000001c',
    '& button': {
      '&:hover': {
        backgroundColor: '#5294ff',
        boxShadow: '0 4px 10px 0 rgba(82, 148, 255, 0.6)',
      },
      '&:disabled': {
        color: 'white',
        opacity: 0.35,
        backgroundColor: '#aeb8d3',
      },
      '&:active': {
        backgroundColor: '#3967b2',
      },
    },
    '& .react-cookie-law-button-wrapper, & .react-cookie-law-policy': {
      float: ({ len }) => (len == 'he' ? 'left!important' : 'right!important'),
    },
  },
}));

const showDelay = 15000;

const CookiesConsent = ({}) => {
  const len = i18next.language.substring(0, 2);
  const classes = useStyles({ len });
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsDisplayed(true);
    }, showDelay);
  }, []);

  return isDisplayed ? (
    <div>
      <CookieBanner
        className={classes.root}
        message={i18next.t(
          'cookies_consent.message',
          'In order to provide a better user experience, Doxer.io uses cookies. By continuing to browse the site you are agreeing to our use of cookies.',
        )}
        policyLink={`/${len}/privacy`}
        privacyPolicyLinkText={i18next.t('cookies_consent.link.privacy', 'Privacy Policy')}
        necessaryOptionText={i18next.t('cookies_consent.option.necessary', 'Necessary')}
        preferencesOptionText={i18next.t('cookies_consent.option.preferences', 'Preferences')}
        showPreferencesOption={false}
        preferencesDefaultChecked={true}
        statisticsOptionText={i18next.t('cookies_consent.option.statistics', 'Statistics')}
        showStatisticsOption={true}
        statisticsDefaultChecked={true}
        marketingOptionText={i18next.t('cookies_consent.option.marketing', 'Marketing')}
        showMarketingOption={true}
        marketingDefaultChecked={true}
        acceptButtonText={i18next.t('cookies_consent.button.accept', 'Accept')}
        declineButtonText={i18next.t('cookies_consent.button.decline', 'Decline')}
        managePreferencesButtonText={i18next.t('cookies_consent.button.manage', 'Manage my cookies')}
        savePreferencesButtonText={i18next.t('cookies_consent.button.save_and_close', 'Save and close')}
        // showDeclineButton	bool	false	optional. Show or hide the decline button
        // dismissOnScroll={true}
        // onAccept	function	Function	optional. Callback called when the consent is given
        // onAcceptPreferences	function	Function	optional. Callback called if preferences cookies is accepted
        // onAcceptStatistics	function	Function	optional. Callback called if statistics cookies is accepted
        // onAcceptMarketing	function	Function	optional. Callback called if marketing cookies is accepted
        // onDeclinePreferences	function	Function	optional. Callback called if preferences cookies is declined
        // onDeclineStatistics	function	Function	optional. Callback called if statistics cookies is declined
        // onDeclineMarketing	function	Function	optional. Callback called if marketing cookies is declined
        wholeDomain={false}
        styles={{
          buttonWrapper: {
            float: 'right',
            marginTop: -20,
          },
          button: {
            color: 'white',
            height: '40px',
            padding: '10px 20px',
            fontSize: '18px',
            fontWeight: '400',
            letterSpacing: '0',
            textTransform: 'none',
            backgroundColor: '#5294ff',
            border: 'none',
            margin: 5,
            borderRadius: 4,
            cursor: 'pointer',
          },
          policy: {},
          optionLabel: {
            height: 'auto',
            width: 'auto',
            minHeight: '14px',
            display: 'inline-block',
            padding: '1px 0px 0px 20px',
            position: 'relative',
            top: '0px',
            left: '0px',
            zIndex: '1',
            cursor: 'default',
            verticalAlign: 'top',
            color: '#6a778c',
            fontSize: '16px',
            letterSpacing: '0.03333em',
          },
          checkbox: {
            position: 'absolute',
            top: '2px',
            left: '-5px',
            width: '14px',
            height: '14px',
            zIndex: '2',
            cursor: 'pointer',
          },
          message: {
            color: '#6a778c',
            fontSize: '16px',
            letterSpacing: '0.03333em',
          },
          // optionWrapper: {},
        }}
        onAccept={() => {}}
        onAcceptPreferences={() => {}}
        onAcceptStatistics={() => {}}
        onAcceptMarketing={() => {}}
      />
    </div>
  ) : null;
};

CookiesConsent.propTypes = {};

CookiesConsent.defaultProps = {};

export default CookiesConsent;
